document.querySelector(".scroll-button").addEventListener("click", () => {
  const servicesSection = document.querySelector("#services")
  servicesSection.scrollIntoView({
    behavior: "smooth",
    block: "start"
  })
})

window.toggleService = function (element) {
  const card = element.closest(".service-card")
  card.classList.toggle("expanded")
}

document.addEventListener("DOMContentLoaded", () => {
  const video = document.querySelector("video")
  const videoBackground = document.querySelector(".video-background")

  video.play().catch(function (error) {
    videoBackground.classList.add("image-fallback")
    video.remove()
  })
})
